<template>
  <div class="visit">
    <h2>欢迎到访</h2>
    <el-form
      class="form-block"
      :rules="rules"
      :model="formData"
      ref="formRef"
    >
      <el-form-item prop="name">
        <template #label>
          <i class="el-icon-s-custom"></i>
        </template>
        <el-input
          v-model="name"
          placeholder="请填写您的称呼(必填)"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <template #label>
          <i class="el-icon-phone"></i>
        </template>
        <el-input
          v-model="mobile"
          placeholder="请填写您的联系方式(必填)"
        >

        </el-input>
      </el-form-item>
      <el-form-item prop="userId">
        <template #label>
          <i class="el-icon-user-solid"></i>
        </template>
        <el-input
            v-model="telephone"
            placeholder="请填写接待经理手机号(必填)"
        >
        </el-input>
      </el-form-item>

    </el-form>
    <el-button
      class="finish"
      @click="handleSubmit"
    >完成登记</el-button>
  </div>
</template>

<script>
import {
  ElButton,
  ElForm,
  ElFormItem,
  ElIcon,
  ElInput,
  ElMessage,
  ElOption,
  ElSelect,
} from "element-plus";
import { onMounted, reactive, ref, toRefs } from "vue";
import { validatePhone } from "@/until";
import { postCustomerVisit } from "@/api/method/customer-visit";
import router from "../../router";

export default {
  name: "CustomerVisit",
  components: {
    [ElForm.name]: ElForm,
    [ElFormItem.name]: ElFormItem,
    [ElInput.name]: ElInput,
    [ElButton.name]: ElButton,
    [ElIcon.name]: ElIcon,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  setup() {
    const formData = reactive({
      name: "",
      mobile: "",
      telephone: "",
    });

    const usernameOptions = ref([]);
    const rules = reactive({
      name: [{ required: true, message: "请输入您的称呼", trigger: "submit" }],
      mobile: {
        required: true,
        validator: (rule, value, callback) =>
          validatePhone(rule, value, callback),
        trigger: "submit",
      },
      telephone: {
        required: true,
        validator: (rule, value, callback) =>
            validatePhone(rule, value, callback),
        trigger: "submit",
      }
    });

    // const getUsers = async () => {
    //   const res = await getAllUsers();
    //
    //   usernameOptions.value = res.data.map((item) => ({
    //     label: item.nickname,
    //     val: item.id,
    //   }));
    // };

    onMounted(() => {
      // getUsers();
    });

    const formRef = ref(null);
    const handleSubmit = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          const res = await postCustomerVisit(formData);
          if (res.code == 200) {
            ElMessage.success("登记成功！");
            router.push('/customer/visit/success')
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          return false;
        }
      });
    };
    return {
      ...toRefs(formData),
      usernameOptions,
      rules,
      formData,
      formRef,
      handleSubmit,
    };
  },
};
</script>

<style lang="scss">
.visit {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #406fff;
  color: white;
  h2 {
    margin: 20px 0;
    text-align: center;
    font-size: 24px;
  }
  .form-block {
    background-color: white;
    width: calc(100vw - 80px);
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 0px 6px 2px rgba(220, 218, 218, 0.18);
    padding: 40px 20px;
    i {
      font-size: 18px;
    }
    .el-select {
      width: 100%;
    }
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      content: "";
    }
  }
  .finish {
    display: block;
    width: 100%;
    margin-top: 40px;
    width: calc(100vw - 40px);
    margin: 0 auto;
    color: #406fff;
    margin-top: 20px;
    border-radius: 10px;
    height: 56px;
    font-size: 18px;
  }
}
</style>